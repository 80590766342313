
import cmsComponent from 'mixins/cmsComponent';
import GHeader from './goose/g-header';
import GWaveContainer from './goose/g-wave-container';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-header',
  components: {
    GHeader,
    GWaveContainer,
  },
  mixins: [cmsComponent],
  props: {
    hideWaveDown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    embedFormContent() {
      return this.blok.show_embed_form ? this.blok.embed_code[0] : [];
    },
    getTheme() {
      return this.blok.theme || 'white';
    },
    getTags() {
      if (typeof this.blok.tags !== 'undefined') {
        return {
          tags: this.blok.tags.map(tag => {
            return {
              tagName: tag.content.name,
              tagLink: parseLinkHref(tag.content.link),
            };
          }),
        };
      }
      return { tags: [] };
    },
    headerTitle() {
      if (typeof this.blok.text !== 'undefined') {
        if (typeof this.blok.text !== 'string') {
          const header = richtext(this.blok.text, true, true);
          return this.interpolateStats(header);
        } else {
          return this.interpolateStats(this.blok.text);
        }
      }
      return undefined;
    },
    headerSubline() {
      if (typeof this.blok.subline !== 'undefined') {
        if (typeof this.blok.subline !== 'string') {
          const subline = richtext(this.blok.subline, true, true);
          return this.interpolateStats(subline);
        } else {
          return this.interpolateStats(this.blok.subline);
        }
      }
      return undefined;
    },
    paddingBottom() {
      // if the theme has been set to something other than white, give it padding
      return typeof this.blok.theme !== 'undefined' &&
        this.blok.theme !== 'white'
        ? 'pb-60'
        : null;
    },
  },
};
