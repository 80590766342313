
import GHeaderMediaHighlight from './goose/g-header-media-highlight';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';
import scrollToAnchor from '~/utils/scrollToAnchor';

export default {
  name: 'cms-header-media-highlight',
  components: {
    GHeaderMediaHighlight,
  },
  mixins: [cmsComponent, scrollToAnchor],
  data() {
    return {
      offsetMobile: -45,
      offsetMobileGeo: -290,
    };
  },
  computed: {
    headerMediaHighlightProps() {
      return {
        slug: {
          full_slug: this.slug,
        },
        header: richtext(this.blok.header, true),
        paragraph: richtext(this.blok.paragraph, true),
        image: this.blok.image.imagePublicUrl,
        videoThumbnail: this.blok.video_thumbnail.imagePublicUrl,
        videoEmbedUrl: this.blok.video_ember_url,
        primaryButtonText: this.blok.primary_button_text,
        primaryButtonTarget: this.getCtaTarget(this.blok.primary_button_target),
        primaryButtonLink: this.getCtaHref(this.blok.primary_button_link),
        secondaryButtonText: this.blok.secondary_button_text,
        secondaryButtonTarget: this.getCtaTarget(
          this.blok.secondary_button_target
        ),
        secondaryButtonLink: this.getCtaHref(this.blok.secondary_button_link),
        signupCtaId: this.blok.signup_cta_id,
        tagText: this.blok.tag_text,
        tagLink: this.getCtaHref(this.blok.tag_link),
        showBreadcrumb: this.blok.show_breadcrumb,
      };
    },
  },
  methods: {
    getCtaTarget(ctaType) {
      return ctaType !== 'link' ? ctaType : '';
    },
    getCtaHref(linkObject) {
      return linkObject.cached_url !== '' ? parseLinkHref(linkObject) : '';
    },
  },
};
