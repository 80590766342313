
import GCtaDark from './goose/g-cta-dark';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-cta-dark',
  components: {
    GCtaDark,
  },
  mixins: [cmsComponent],
  computed: {
    full_slug() {
      return {
        full_slug: this.slug,
      };
    },
    capterraStats() {
      return {
        score: this.stats['capterra_score'],
        total_reviews: this.stats['capterra_total_reviews'],
      };
    },
  },
};
